import Swiper from 'swiper';

export const slider = ($element) => {
    const defaultConfig = {
        pagination: {
            el: '.swiper-pagination',
            type: 'progressbar',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          on: {
            init: function () {
                const $fraction = $(this.el).find('.swiper-fraction');
                const activeSlide = parseInt(this.realIndex) + 1;

                $fraction.html(`${activeSlide}/${this.slides.length}`);
            },
            slideChange: function () {
                const $fraction = $(this.el).find('.swiper-fraction');
                const activeSlide = parseInt(this.realIndex) + 1;

                $fraction.html(`${activeSlide}/${this.slides.length}`);
            }
        }
    }

    $element.each(function () {
        const $this = $(this);
        const customConfig = $this.data('slider');
        let swiper = new Swiper($this.get(0), Object.assign({}, defaultConfig, customConfig));
    });

   
}