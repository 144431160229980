import 'magnific-popup';

export const mfp = ($element) => {
    $element.magnificPopup({
        type: 'inline',
        fixedContentPos: true,
        fixedBgPos: true,
        overflowY: 'auto',
        closeBtnInside: true,
        midClick: true,
    })
}