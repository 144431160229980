import 'time-to';

export const timer = ($element) => {
	$element.each(function () {
		const $this = $(this);
		$this.timeTo({
			timeTo: new Date(new Date($this.data('timer'))),
			displayDays: 2,
			displayCaptions: true,
			lang: 'ru',
			fontFamily: false,
			fontSize: 28,
			captionSize: 14
		});
	});
}