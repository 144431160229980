import "../sass/main.scss";
import "../sass/font.scss";
import 'jquery';

import { timer } from './components/timer';
import { slider } from "./components/slider";
import { mfp } from "./components/magnific-popup";
import { goTop } from "./components/go-top";

timer($('[data-timer]'));
slider($('[data-slider]'));
mfp($('[data-mfp]'));
goTop($('[data-go-top]'));

function requireAll(r) {
    r.keys().forEach(r);
}
requireAll(
    require.context("../../public/static/svg/", true, /\.svg$/)
);
